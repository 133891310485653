.basebox {
    display: block;
    box-sizing: border-box;
    /* background-color: #215463; */
    background-color: rgb(6, 55, 123);
    padding: 12px;

    color: white;
    font-size: 16pt;
    font-weight: 400;

    margin-top: 12px;

    flex-shrink: 0;
}

@media screen and (min-width: 470px) {
    .basebox-pad {
        border-right: 12px solid white;
        border-left: 12px solid white;
    }
}

.fixed-height {
    height: 600px;
}

.auto-height {
    height: auto;
}

.fixed-width {
    width: 375px;
}

.auto-width {
    flex-grow: 1;
    flex-basis: 375px;
    width: auto;
    min-width: 375px;
    max-width: 100%;
}
