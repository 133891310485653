.card-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
}

.card-container > div {
    margin-top: 12px;
}

/* If screen is big enough (to show the sidebar), add margin on left of cards */
@media screen and (min-width: 470px) {
    .card-container > div {
        margin-left: 12px;
    }

    .card-container {
        padding-right: 12px;
    }
}
