@import '../../../vars.css';

.container {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    display: block;
    font-size: 12pt;
    /* background-color: #32798f; */
    background-color: var(--color-highlight-1);
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 2px;
    margin: 4px 2px;
    color: white;
}

.button:hover {
    background-color: var(--color-highlight-1-hover);
}

.button {
    cursor: pointer;
}

.label {
    display: inline-block;
    vertical-align: middle;
}

.data {
    display: inline-block;
    margin-left: 10px;
    height: 20px;
    line-height: 20px;
    background-color: brown;
    border-radius: 10px;
    padding: 2px 6px;
    vertical-align: middle;
    text-align: center;
    font-size: 10pt;
    font-weight: bold;
}

.data > div {
    display: inline-block;
    vertical-align: top;
}
