.breadcrumbs {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    color: white;
    height: 100%;
    padding-left: 12px;
}

.breadcrumbs > p {
    display: block;
    font-size: 18pt;
}

