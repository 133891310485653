.row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.col {
    flex: 1 0 50%;
}
