.App {
  display: inline-flex;
  overflow: hidden;
  flex-wrap: nowrap;
  min-width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 375px;
  /* background-color: #15353E; */
  background-color: white;
}
