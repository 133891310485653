.info-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    flex-shrink: 0;
    width: 100%;
    height: auto;
    box-sizing: border-box;
}

.info-container > div {
    margin-top: 12px;
}

a:link,
a:visited,
a:hover,
a:active {
    color: white;
}

.info-container p {
    font-family: roboto, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: medium;
}

/* If screen is big enough (to show the sidebar), add margin on left of cards */
@media screen and (min-width: 470px) {
    .info-container > div {
        margin-left: 12px;
    }

    .info-container {
        padding-right: 12px;
    }
}
