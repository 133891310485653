.h2-title, .title-input{
    color: white;
    font-size: 24pt;
    margin-top: 6px;
    margin-bottom: 5px;
    font-weight: 300;
}

.title-input {
    display: block;
    background-color: transparent;
    font-family: inherit;
    border: none;
    padding: 0;
    width: 100%;
}

.divider {
    background-color: white;
    margin-top: 0 !important;
    margin-bottom: 8px
}
