.sidebar {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    min-height: 100vh;
    width: 195px;
    background-color: rgb(6, 55, 123);;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 0 18px;
}

@media screen and (max-width: 655px) {
    .sidebar {
        width: 50px;
        margin-right: 1px;
        padding: 0;
    }
}

.sidebar > div {
    margin-left: auto;
    margin-right: auto;
}

.logo {
    height: 80px;
}
